import { useFetcher } from '@remix-run/react';
import { $path } from 'remix-routes';

import { LogOutIcon } from '@yonderlabs-packages/icons';

import { Button } from '~/src/ui';

export function LogoutFormButton() {
  const fetcher = useFetcher();

  return (
    <fetcher.Form id='logout-form-button' method='POST' action={$path('/api/identity/account/logout')}>
      <Button variant='outline' size='sm'>
        <LogOutIcon className='h-5 w-5' />
      </Button>
    </fetcher.Form>
  );
}
